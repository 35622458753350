<!-- 特色活动 -->
<template>
    <div class="activity">
        <img class="logo" src="../../assets/active/logo.png" alt="">
        <div class="back flex-align">
            <img src="../../assets/active/back.png" alt="" @click="goBack">
            <span @click="goBack">返回</span>
        </div>
        <div class="content">
            <div class="title_wrap flex-align-around">
                <div class="point"></div>
                <div class="title">{{$route.query.activityParentName}}</div>
                <div class="point"></div>
            </div>
            <div class="works">
                <!-- 筛选条件 -->
                <div class="searchRule" v-if="hasActivityData">
                    <!-- <div class="item_wrap" v-if="userRole==5">
                        <div class="item_name">区域:</div>
                        <div class="items" :class="{'item_active':defaultAdd==index}"
                            v-for="(item,index) in addressList" :key="index" @click="changeAdd(index)">{{item}}</div>
                    </div> -->
                    <div class="item_wrap" v-if="userRole==5">
                        <div class="item_name">学校:</div>
                        <div class="items_box">
                            <div class="items" :class="{'item_active':defaultSchool==index}"
                                v-for="(item,index) in schoolList" :key="index" @click="changeSchool(index,item.id)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                    <div class="item_wrap">
                        <div class="item_name">年级:</div>
                        <div class="items_box">
                            <div class="items" :class="{'item_active':defaultGrade==index}"
                                v-for="(item,index) in gradeList" :key="index" @click="changeGrade(index,item.id)">
                                {{item.name}}</div>
                        </div>
                    </div>
                    <div class="item_wrap" :class="{'more':more}">
                        <div class="item_name">班级:</div>
                        <div class="items_box">
                            <div class="items" :class="{'item_active':defaultClass==index}"
                                v-for="(item,index) in classList" :key="index" @click="changeClass(index,item.id)">
                                {{item.name}}</div>
                            <i class="showMore" @click="showMore" v-if="classList.length>6"
                                :class="more?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                        </div>
                    </div>
                </div>
                <div class="serarch" v-if="hasActivityData">
                    <el-input placeholder="输入学生姓名搜索" suffix-icon="el-icon-search" @input='inputChange' v-model="value">
                    </el-input>
                </div>
                <div class="item_wrap flex-align">
                    <template v-if="activityList.length>0">
                        <div class="item" v-for="(item,index) in activityList" :key="index" @click="preview(item.id)">
                            <div class="img_wrap flex-align-center">
                                <img :src="item.file" alt="">
                            </div>
                            <div class="info">
                                <span class="wes">{{item.schoolName}}</span>
                                <span class="wes">作者：{{item.userName}}</span>
                                <span class="wes">{{item.createTime}}</span>
                            </div>
                        </div>
                    </template>
                    <el-empty class="empty" v-else description="暂无作品信息"></el-empty>
                </div>
            </div>
            <div class="pages flex-align-around" v-if="hasActivityData">
                <el-pagination background layout="prev, pager, next,total" :page-size='limit' :total="total"
                    @current-change="pageChange" @prev-click="prev" @next-click="next">
                </el-pagination>
            </div>
        </div>
        <!-- 查看大图轮播 -->
        <el-dialog :visible="activityDialogVisible" custom-class="activitydialog" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="banner_wrap flex-align">
                <img class="colse" @click="activityDialogVisible = false" src="../../assets/active/close.png" alt="">
                <!-- <img src="../../assets/active/left.png" class="left" alt="" @click="toggle('prev')"> -->
                <i class="el-icon-arrow-left" @click="toggle('prev')"></i>
                <el-carousel arrow="never" indicator-position="none" ref="carousel" :autoplay="false"
                    @change='carouselChange'>
                    <el-carousel-item v-for="(item,index) in activityList" :key="index">
                        <div class="detail_wrap flex-align" :class="{'noResult':!currentWorkInfo.hasResult}">
                            <div class="imgBox">
                                <img id='myImg' class="work" :src="currentWorkInfo.imgUrl" alt="" @mousedown="mousedown"
                                    @mousewheel="mousewheel" :style="{width:imgWidth+'px'}">
                            </div>
                            <div class="right" :class="{'noRight':!currentWorkInfo.hasResult}">
                                <div class="title flex-align">
                                    <img src="../../assets/active/cup.png" alt="">
                                    获奖作品
                                </div>
                                <div class="line"></div>
                                <div class="item flex-align">
                                    <span>作者姓名：</span>
                                    <span>{{currentWorkInfo.name}}</span>
                                </div>
                                <div class="item flex-align">
                                    <span>作品ID：</span>
                                    <span>{{currentWorkInfo.id}}</span>
                                </div>
                                <div class="item2">
                                    <span>综合评价：</span>
                                </div>
                                <div class="star flex-align-around">
                                    <div class="star_wrap">
                                        <div class="star_item flex-align"
                                            v-for="(item,index) in currentWorkInfo.commentType" :key="index">
                                            <span class="item_name flex-align"><span
                                                    class="point"></span>{{item.name}}</span>
                                            <el-rate disabled-void-color='#CCCCCC' allow-half disabled
                                                v-model="item.count">
                                            </el-rate>
                                        </div>
                                    </div>
                                </div>
                                <div class="item2">
                                    <span>作品点评：</span>
                                </div>
                                <div class="comment">{{currentWorkInfo.comment}}</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <!-- <img src="../../assets/active/right.png" class="right" alt="" @click="toggle('next')"> -->
                <i class="el-icon-arrow-right" @click="toggle('next')"></i>
            </div>
            <div class="info flex-align-center" v-if="!currentWorkInfo.hasResult">
                <span>作者姓名:{{currentWorkInfo.name}}</span>
                <span>学校:{{currentWorkInfo.schoolName}}</span>
                <span>作品ID:{{currentWorkInfo.id}}</span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                imgWidth: 450,
                value: '',
                activityDialogVisible: false,
                starValue: 2,
                page: 1,
                limit: 12,
                total: null,
                activityList: [],
                currentWorkInfo: {
                    name: '',
                    id: '',
                    imgUrl: '',
                    commentType: [{
                            name: '作品完整度',
                            count: 0
                        },
                        {
                            name: '例字相似性',
                            count: 0
                        }, {
                            name: '占格一致性',
                            count: 0
                        }, {
                            name: '内容整齐度',
                            count: 0
                        },
                    ],
                    comment: '',
                    hasResult: true
                },
                addressList: ['全部', '海淀区', '丰台区', '门头沟区', '朝阳区'],
                schoolList: [],
                gradeList: [],
                classList: [],
                defaultAdd: 0,
                defaultSchool: 1,
                defaultGrade: 1,
                defaultClass: 1,
                userRole: null,
                schoolId: null,
                gradeId: null,
                classId: null,
                more: false,
                isActive: false,
                currentScale: 1,
                timer: null,
                source: null,
                hasActivityData:false
            }
        },
        methods: {
            showMore() {
                this.more = !this.more
            },
            goBack() {
                if (this.source == 'newactivity_list') {
                    this.$router.go(-1)
                } else {
                    this.$router.push("/home");
                }
            },
            preview(uploadId) {
                console.log('uploadId:', uploadId)
                let data = {
                    uploadId
                };
                this.$Api.Activity.getWorkDetail(data)
                    .then(res => {
                        console.log('作品详情:', res);
                        if (res.data.scoreAvg < 0) {
                            this.currentWorkInfo.hasResult = false;
                            this.currentWorkInfo.imgUrl = res.data.file;
                            this.currentWorkInfo.name = res.data.studentName;
                            this.currentWorkInfo.id = res.data.wordId;
                        } else {
                            this.currentWorkInfo.hasResult = true;
                            this.currentWorkInfo.name = res.data.studentName;
                            this.currentWorkInfo.id = res.data.wordId;
                            this.currentWorkInfo.comment = res.data.comment;
                            this.currentWorkInfo.imgUrl = res.data.file
                            let wzd = Math.floor((res.data.writeCount / res.data.maxNum) * 5);
                            let xsx = Math.floor(res.data.scoreAvg / 10);
                            let yzx = Math.floor(res.data.sizeOffset / 10);
                            let zqd = Math.floor(res.data.locationOffset / 10);
                            console.log('wzd:', wzd);
                            console.log('xsx:', xsx);
                            console.log('yzx:', yzx);
                            console.log('zqd:', zqd);
                            this.$set(this.currentWorkInfo.commentType, 0, {
                                ...this.currentWorkInfo.commentType[0],
                                count: wzd
                            });
                            this.$set(this.currentWorkInfo.commentType, 1, {
                                ...this.currentWorkInfo.commentType[1],
                                count: xsx * 0.5
                            });
                            this.$set(this.currentWorkInfo.commentType, 2, {
                                ...this.currentWorkInfo.commentType[2],
                                count: yzx * 0.5
                            });
                            this.$set(this.currentWorkInfo.commentType, 3, {
                                ...this.currentWorkInfo.commentType[3],
                                count: zqd * 0.5
                            });
                        }
                        this.activityDialogVisible = true;
                    })
                    .catch(err => {
                        console.log('作品详情失败:', err)
                    })
            },
            getActivityList() {
                // 获取活动数据
                let data = {
                    activityId: this.$route.query.activityParentId,
                    type: 1,
                    teacherId: this.userRole == 2 ? sessionStorage.getItem('teacherId') : '',
                    page: this.page,
                    limit: this.limit,
                    userName: this.value,
                    schoolId: this.schoolId,
                    grade: this.gradeId,
                    classId: this.classId
                }
                this.$Api.Activity.getWorks(data)
                    .then(res => {
                        // console.log('活动数据:', res);
                        this.activityList = res.data.list;
                        this.total = res.data.totalCount;
                    })
                    .catch(err => {
                        console.log('获取活动失败:', err)
                    })
            },
            inputChange(val) {
                console.log(val);
                this.getActivityList()
            },
            pageChange(page) {
                this.page = page;
                this.getActivityList()
            },
            prev(page) {
                this.page = page;
                this.getActivityList()
            },
            next(page) {
                this.page = page;
                this.getActivityList()
            },
            toggle(type) {
                if (type == 'prev') {
                    this.$refs.carousel.prev()
                } else {
                    this.$refs.carousel.next()
                }
            },
            carouselChange(currentIndex, oldIndex) {
                // console.log('当前索引:', currentIndex, '原索引:', oldIndex);
                this.preview(this.activityList[currentIndex].id)
            },
            changeAdd(index, id) {
                this.defaultAdd = index;
            },
            changeSchool(index, id) {
                this.defaultSchool = index;
                this.defaultGrade = 1;
                this.schoolId = id;
                this.getGrade()
            },
            changeGrade(index, id) {
                this.defaultGrade = index;
                this.defaultClass = 1;
                this.gradeId = id;
                this.getClass(id);
            },
            changeClass(index, id) {
                this.defaultClass = index;
                this.classId = id;
                console.log('选择班级重新获取作品');
                this.getActivityList();
            },
            // 获取用户角色
            getUserRole() {
                let roles = JSON.parse(sessionStorage.getItem('roles'));
                console.log('用户角色:', roles);
                if (roles.length == 1) {
                    this.userRole = roles[0]
                } else {
                    this.userRole = Math.max(...roles)
                }
                if (this.userRole == 5) {
                    this.getSchool()
                } else {
                    this.schoolId = sessionStorage.getItem('schoolId');
                    this.getGrade()
                }
                return Promise.resolve(this.userRole)
            },
            // 获取学校
            getSchool() {
                let data = {
                    activityId: this.$route.query.activityParentId
                }
                this.$Api.Activity.activitySchool(data)
                    .then(res => {
                        // console.log('学校列表:', res);
                        let obj = {
                            id: '',
                            name: '全部'
                        };
                        res.data.unshift(obj);
                        this.schoolList = res.data;
                        this.schoolId = res.data[1].id;
                        this.getGrade();
                    })
                    .catch(err => {
                        console.log('获取学校失败:', err)
                    })
            },
            // 获取年级
            getGrade() {
                let data = {
                    activityId: this.$route.query.activityParentId,
                    schoolId: this.schoolId,
                    teacherId: this.userRole == 2 ? sessionStorage.getItem('teacherId') : '',
                }
                this.$Api.Activity.activityGrade(data)
                    .then(res => {
                        console.log('年级列表:', res);
                        // 判断年级列表是否有数据
                        if (res.data.length == 0) {
                            this.hasActivityData = false;
                        } else {
                            this.hasActivityData = true;
                            let obj = {
                                id: '',
                                name: '全部'
                            };
                            res.data.unshift(obj);
                            this.gradeList = res.data;
                            this.gradeId = res.data[1].id;
                            this.getClass(res.data[1].id)
                        }
                    })
                    .catch(err => {
                        console.log('获取年级失败:', err);
                        this.activityList = [];
                    })
            },
            // 获取班级
            getClass(grade) {
                let data = {
                    activityId: this.$route.query.activityParentId,
                    grade,
                    schoolId: this.schoolId,
                    teacherId: this.userRole == 2 ? sessionStorage.getItem('teacherId') : '',
                }
                this.$Api.Activity.activityClass(data)
                    .then(res => {
                        // console.log('班级列表:',res);
                        let obj = {
                            id: '',
                            name: '全部'
                        };
                        res.data.unshift(obj);
                        this.classList = res.data;
                        this.classId = res.data[1].id;
                        console.log('重新获取作品');
                        this.getActivityList();
                    })
                    .catch(err => {
                        console.log('获取班级失败:', err);
                        this.activityList = [];
                    })
            },
            mousewheel(e) {
                const img = document.getElementById('myImg');
                this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
                if (e.deltaY > 0) {
                    console.log('🖱向下滚动，图标缩小');
                    this.imgWidth = this.imgWidth - 20;
                } else {
                    console.log('🖱向上滚动,图片放大');
                    this.imgWidth = this.imgWidth + 20
                }
                // console.log(this.imgWidth)
            },
            // 拖动图片
            mousedown(e) {
                console.log('鼠标点击事件')
                // 阻止默认事件
                e.preventDefault();
                let box = document.querySelector('.detail_wrap');
                let img = document.getElementById('myImg');
                let x = e.pageX - img.offsetLeft;
                let y = e.pageY - img.offsetTop;
                console.log('x:', x);
                console.log('y:', y);
                // 添加鼠标移动事件
                box.addEventListener('mousemove', move);

                function move(e) {
                    console.log('鼠标移动事件:', e)
                    img.style.left = e.pageX - x + 'px';
                    img.style.top = e.pageY - y + 'px';
                };
                // 鼠标抬起移除事件
                img.addEventListener('mouseup', function () {
                    console.log('鼠标抬起事件')
                    box.removeEventListener('mousemove', move)
                });
                // 鼠标离开box移除事件
                box.addEventListener('mouseout', function () {
                    console.log('鼠标离开box事件')
                    box.removeEventListener('mousemove', move)
                })
            }
        },
        mounted() {
            this.getUserRole();
        },
        beforeRouteEnter(to, form, next) {
            next(vm => {
                if (form.name == 'newactivity_list') {
                    vm.source = 'newactivity_list'
                }
            })
        }
    };
</script>
<style lang='less' scoped>
    @import url("./less/activity.less");
</style>